<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Applauds | Achievements</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Applauds</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
              <div class="page-title-text">
                <b-row>
                  <b-col cols="12"><h5>Congratulations!</h5></b-col>
                  <b-col cols="12 mt-2"><p>Share your achievements with us, Let's celebrate together.</p></b-col>
                  <!-- <b-col cols="12" >                          
                    <div class="thira-button">
                      <router-link to="/share/Achievements">Share Your Achievements</router-link>
                    </div> 
                  </b-col>                 -->
                </b-row>    
              </div>
              <div  v-for="Data in RespUserAwards" v-bind:key="Data.id">       
                <b-row class="row no-gutters profile-card" >
                  <div class="col-md-12 Thira profile-card-5">
                    <div class="card-img-block">
                      <img v-bind:src="Data.post_img" alt="Image">
                    </div>
                    <b-row class="page-title-text" >
                      <h5>{{Data.post_title}}</h5>
                    </b-row>  
                    <p class="pre-formatted" v-if='Data.post_desc !== ("" || null)' ><span v-html="Data.post_desc"></span></p>    
                  </div>
                </b-row>
              </div>                  
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- <Side Recent Performers/> -->
                <SideRecentPerformers/>    
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>              
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
  name:"Achievements",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Awards & Recognitions | Performing Arts | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },     
    data() {
      return {
        RespUserAwards: [],
      }
    },
    mounted()
    {
      this.ReadAllUserAwards()

    },
    methods: {
        ReadAllUserAwards(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllUserAwards')
        .then((resp)=>{
        this.RespUserAwards=resp.data.UserAwards;
      })
      },  
    },
    computed: { 
  }
  }
</script>

<style scoped>

.profile-card {
    margin-top:10px;
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
}

.profile-card-5 .card-img-block {
    float: center;
    width: 300px;
    padding: 10px 10px 10px 10px;
    height: auto;
}

.profile-card-5 .card-img-block img{
    float: left;
    width: 200px;
    padding: 0px 15px 9px 2px;
    height: 200px;
    border-radius: 15px;

}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    margin-top:5px;
    font-size:14px;
    font-weight:300;
    padding: 1px 10px;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  /* display: inline-block; */
}
.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}
.page-title-text p {
    margin-top: -13px;  
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}
</style>
